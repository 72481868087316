.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  padding: 16px 32px;
  transform: translate3d(0, 0, 0);
  white-space: nowrap;
  border: none;
  cursor: pointer;
  position: relative;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.button--small {
    height: 30px;
  }

  &:active {
    top: 2px;
  }
}

.button--primary {
  background-color: $color-secondary;
  color: $color-white;
  min-width: 180px;

  &:hover {
    opacity: 1;
    background: $color-secondary--hover;
    color: $color-white;
  }
}

.button--link {
  background: transparent;
  color: $color-link;
  font-size: 16px;
  height: auto;
  padding: 0;
  white-space: normal;
  text-align: left;
  font-weight: $font-weight-regular;
  text-decoration: underline;

  &:hover {
    opacity: 1;
    color: $color-link--hover;
  }

  &.button--link-light {
    color: $color-white;

    &:hover {
      color: $color-secondary;
    }
  }
}

.button--block {
  width: 100%;
}

.button--working {
  background-color: gray;
  color: white;
  pointer-events: none;

  .MuiCircularProgress-root {
    height: 26px !important;
    width: 26px !important;
  }
}

.button-ico-wrap {
  display: flex;
  align-items: center;

  img {
    margin-left: 32px;
  }

  .message {
    margin-left: 8px;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }
}

.button--group {
  display: flex;

  .button-ico-wrap:first-child,
  .button:first-child {
    margin-right: 16px;
  }
}

.button__icon {
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: .7;
  }

  &:active {
    top: 2px;
  }
}