.banner {
  border: 1px solid $color-dark-blue;
  height: 100%;

  &__header {
    padding: 12px 24px;
    color: $color-white;
    background-color: $color-dark-blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  &__children {
    padding: 24px;
  }
}