.select {
  margin: 16px 0;
}

.select__inner {
  width: 100%;
  background-color: $color-grey;
  padding: 4px 16px;
}

.select__label {
  display: block;
  flex: 0 0 auto;
  white-space: nowrap;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
}

.select__control {
  border: none !important;
  box-shadow: none !important;
  background: none !important;
}


.select__value-container {
  align-items: flex-start !important;
  flex-direction: column;
  padding: 0 !important;
}

.select__single-value {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

.select__menu {
  color: $color-dark-blue !important;
  margin-left: -16px;
  margin-top: 16px !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.select__menu-list {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.select__control {
  cursor: pointer !important;
}

.select__indicator {
  opacity: 0.6 !important;
  padding-right: 0 !important;

  .select__control--is-disabled & {
    display: none;
  }
}

.select__error {
  padding: 8px 0;
  color: #EF476F;
  display: block;
  font-weight: 600;
}