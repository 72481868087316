.spinner {
  display: flex;
  align-items: center;

  svg {
    color: $color-white !important;
  }
}

.spinner--absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
