.bill {
  &__signature {
    word-break: break-all;
    font-size: 10px;
    line-height: 12px;
    max-width: 80%;
  }

  &__state {
    text-transform: lowercase;
  }
}
