// Typography

.txt-algn-center {
  text-align: center;
}

// Widths

.max-w-688 {
  max-width: 688px;
}

// Margins

.mar-auto {
  margin: auto;
}

// Flex

.flex {
  display: flex;

  &-align-c {
    align-items: center;
  }
}

.flex-group-2 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;

  &__item {
    margin: 0 16px;
    width: calc(50% - 32px);

    @media (max-width: 760px) {
      width: 100%;
    }
  }
}