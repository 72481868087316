.textfield {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}

.textfield__input {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $color-dark-blue;
  width: 100%;
  max-width: calc(100% - 32px);
  border: 0;
  border-radius: 0;
  background: transparent;
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 12px 16px;
  background-color: $color-grey;

  &:focus {
    outline: 0;
  }

  .textfield--hidden-password & {
    -webkit-text-security: disc;
  }

  .textfield--transparent & {
    opacity: 0;
  }
}

.textfield__label {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
  display: block;

  &.with-tooltip {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 16px;
      height: 16px;
      font-size: 12px;
      padding: 8px;
      background: $color-primary;
      color: white;
    }

    .MuiTooltip-tooltip {
      font-size: 14px;
      background: white;
      color: black;
      padding: 8px;
      border: 1px solid black;
      max-width: 480px;
      margin-bottom: 4px !important;
    }
  }

  .copy-btn {
    margin: 0 16px 0 auto;
    cursor: pointer;
    position: relative;

    &:active {
      top: 2px;
    }
  }
}

.textfield__error {
  padding: 8px 0;
  color: #EF476F;
  display: block;
  font-weight: 600;
}