// Colors
$color-primary: #0C0A3E;
$color-primary--hover: #4E3EB6;
$color-secondary: #4E3EB6;
$color-secondary--hover: #0C0A3E;

$color-white: #ffffff;
$color-black: #1d1f22;
$color-dark-blue: #0C0A3E;
$color-violet: #4E3EB6;
$color-grey: #F6F4F5;
$color-error: #EF476F;
$color-link: $color-primary--hover;
$color-link--hover: $color-primary;

// Widths
$container-width: 1040px;

// Paddings
$container-padding: 24px 16px;

// Typography
$font-family-base: 'Cabin', sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;