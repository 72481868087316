@import './utils.scss';
@import './variables.scss';

@import '../components/Banner/Banner.scss';
@import '../components/Bill/Bill.scss';
@import '../components/Button/Button.scss';
@import '../components/Header/Header.scss';
@import '../components/KeyPair/KeyPair.scss';
@import '../components/Order/Order.scss';
@import '../components/Select/Select.scss';
@import '../components/Spacer/Spacer.scss';
@import '../components/Spinner/Spinner.scss';
@import '../components/TextField/TextField.scss';

@font-face {
  font-family: "grotesk";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/SpaceGrotesk-Light.ttf") format("truetype");
}

@font-face {
  font-family: "grotesk";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/SpaceGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "grotesk";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/SpaceGrotesk-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "grotesk";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/SpaceGrotesk-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "grotesk";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/SpaceGrotesk-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'grotesk';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

}

.container {
  padding: $container-padding;
  max-width: $container-width;
  margin: auto;
}

h3 {
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
}

h4 {
  font-size: 22px;
  line-height: 32px;
  font-weight: normal;
}