.order {
  &-notification {
    padding: 8px 0;

    img {
      margin-right: 8px;
    }
  }

  &-notification-group {
    display: flex;

    .order-notification:first-child {
      margin-right: 40px;
    }
  }

  &-error {
    color: #EF476F;
    font-weight: 600;
  }
}
